/* eslint-disable @next/next/no-img-element */
import { prefetchHomeProps } from '@crone/shared/server/prefetchers';
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import { GetStaticProps, NextPage } from 'next';
// import { useRouter } from 'next/router';
// import { useState } from 'react';
import Link from 'next/link';
import { APP_STORE_LINK, SIGNIN_PATH } from '@crone/shared/config/constants';
import { useRouter } from 'next/router';
import Layout from '@crone/shared/components/shared/Layout';
import { NextSeo } from 'next-seo';
import { FaPlayCircle } from 'react-icons/fa';
import RandomBg from '@crone/shared/components/landing/RandomBg';
import React, { useState } from 'react';
import Image from 'next/image';
import Button from '@crone/shared/components/elements/Button';
import {
  CRONE_DESCRIPTION,
  CRONE_TAGLINE,
  CRONE_URI,
} from '@crone/shared/config/constants';

interface PageProps {
  dehydratedState: DehydratedState;
}

const GreatsSection = ({
  title,
  names,
  color,
}: {
  title: string;
  names: string[];
  color: 'rose' | 'sky' | 'orange' | 'emerald' | 'indigo';
}) => {
  return (
    <div className="p-3 md:p-5 space-y-2 transition duration-300 bg-white shadow rounded-2xl hover:shadow-xl">
      <div
        className={`flex items-center justify-center w-8 h-8 text-white rounded-full bg-${color}-400`}
      >
        <i className="text-3xl bx bxs-bolt"></i>
      </div>
      <div className="space-y-2.5">
        <h4 className="text-xl font-bold font-serif">{title}</h4>
        <ul className="text-sm uppercase text-gray-800">
          {names.slice(0, 15)?.map((name, key) => (
            <li key={`great_name_${key}`}>{name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Testimonial = ({
  description,
  name,
}: {
  description: string;
  name: string;
}) => {
  return (
    <div className="flex flex-col p-6 transition duration-300 bg-white shadow rounded-2xl hover:shadow-xl">
      <dt className="flex-grow">
        <p className="text-base lg:text-lg">{description}</p>
      </dt>
      <dd className="mt-5 mb-2.5">
        <img src="img/landing/v2/rating.svg" alt="Rating" className="h-5" />
      </dd>
      <dd className="font-semibold text-gray-900">{name}</dd>
    </div>
  );
};
const SubhookSection = ({
  title,
  children,
  color,
  even,
  screenshotName,
}: {
  title: string;
  children: React.ReactNode;
  color: 'rose' | 'sky' | 'orange' | 'emerald' | 'indigo'; // these are on the theme.js safelist
  even: boolean;
  screenshotName:
    | 'reading'
    | 'answer'
    | 'writing'
    | 'trivia'
    | 'jigsaw'
    | 'complete'; // these are stored
}) => {
  return (
    <section
      className={`bg-${color}-400 overflow-hidden rounded-none lg:rounded-3xl`}
    >
      <div className="container max-w-4xl px-5 py-8 mx-auto md:py-12 lg:px-10 xl:py-24 xl:px-0">
        <div className="grid items-start gap-5 md:grid-cols-2 lg:gap-8">
          <div
            className={`order-last ${
              even ? 'md:order-last' : 'md:order-first'
            }`}
          >
            <div className="w-full h-full px-5 pt-5 -mb-24 bg-gray-300 border border-b-0 border-white rounded-t-3xl bg-opacity-40 border-opacity-40 backdrop-filter backdrop-blur">
              <img
                src={`img/landing/v2/screenshots/${screenshotName}.jpg`}
                alt={title}
                className="shadow-2xl rounded-t-2xl"
              />
            </div>
          </div>

          <div className="space-y-5 pt-5">
            <h5 className="text-3xl font-bold text-white font-serif">
              {title}
            </h5>
            <div className="text-lg text-white lg:text-2xl">{children}</div>
          </div>
        </div>
      </div>
    </section>
  );
};
const HomeInfoPage: NextPage<PageProps> = () => {
  // const router = useRouter();
  // const [showVideo, setShowVideo] = useState<boolean>(false);

  const [showVideo, setShowVideo] = useState(true);
  const router = useRouter();

  return (
    <>
      <NextSeo
        title={CRONE_TAGLINE}
        description={CRONE_DESCRIPTION}
        canonical={CRONE_URI}
      />
      <Layout noMargins={true} hideUntilScroll={true}>
        <main className="space-y-5 lg:m-5 lg:mb-0">
          <div className="flex items-center container max-w-5xl px-5 py-8 mx-auto md:py-12 lg:px-10 xl:py-24 xl:px-0 min-h-[calc(100vh-150px)]">
            <div className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-12 items-center">
              <div className="relative flex-grow my-8 max-w-lg">
                <img
                  src="img/landing/v2/group-photo-min.svg"
                  alt="Crone characters, group photo"
                  className="filter drop-shadow-2xl"
                />
              </div>
              <div className="w-full sm:w-2/3 md:w-3/5 lg:w-1/2 flex-shrink-0">
                {/* <a href="#0">
              <img
                src="shared-dupe/logos/192x192.png"
                alt="App Icon"
                className="h-16 w-16 rounded-lg transition duration-300 filter drop-shadow-lg hover:opacity-70"
              />
            </a> */}
                <div className="space-y-8 text-gray-900 lg:max-w-[500px] text-center mx-auto">
                  <h1 className="text-3xl font-bold lg:text-4xl text-gray-800">
                    <span className="premium-indigo-text">Feel smarter</span>{' '}
                    reading books as bite-sized games
                    {/* &quot;Duolingo&quot; for the world&apos;s greatest books and
                    ideas */}
                    {/* A fun way to feel smart, eloquent, and well-read */}
                    {/* A free, fun way to become well‑rounded and well‑read */}
                    {/* Free, gamified episodes with history&apos;s greatest minds */}
                  </h1>
                  <div className="flex flex-col mx-auto space-y-3 md:space-y-5 items-center max-w-64 w-full px-4">
                    <Link
                      href={{ pathname: '/welcome', query: router.query }}
                      className="cursor-pointer uppercase w-full font-semibold text-sm text-center p-2.5 bg-sky-500 text-white shadow-hard-down shadow-[0_4px_0] shadow-sky-700 border-sky-700 border rounded-xl block"
                    >
                      Get started
                      {/* <span className="text-sm font-thin block">
                        It&apos;s free!
                      </span> */}
                    </Link>
                    <Link
                      href={{ pathname: SIGNIN_PATH, query: router.query }}
                      className="cursor-pointer uppercase w-full font-bold text-sm text-center p-2.5 bg-white text-sky-600 shadow-hard-down shadow-[0_4px_0] shadow-gray-300 border-gray-300 border rounded-xl block"
                    >
                      I have an account
                      {/* <span className="text-sm font-thin block">
                        It&apos;s free!
                      </span> */}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="bg-gray-900 rounded-none lg:rounded-3xl">
            <div className="container max-w-5xl px-3 md:px-5 py-8 mx-auto md:py-12 lg:px-10 xl:py-24 xl:px-0">
              <div className="text-gray-400 text-center uppercase text-md">
                Coming Fall 2024
              </div>
              <h2 className="text-center font-serif text-4xl font-bold text-gray-100">
                Read Homer&apos;s Odyssey on Crone
                {/* What it feels like to use Crone for a month */}
                {/* A new way to learn from old books and great minds */}
              </h2>
              <div className="mt-4">
                {/* <p>
                  This is what it feels like to use Crone long-term (as
                  described by our users and team). You start or end your day
                  with Crone. You feel smarter. When you&apos;re alone, you feel
                  the companionship of history&apos;s greatest minds. When
                  you&apos;re with others, you&apos;ve become one of the most
                  interesting people in any room. You&apos;re living what
                  Aristotle called the happiest life of all: the contemplative
                  life.
                </p> */}
                {/* <p>
                  We built Crone to combine deep reading and writing into
                  bite-sized meditations. Crone facilitates a more examined and
                  prolific life through immersive conversation with the greatest
                  thinkers of all time.
                </p> */}
                {/* <ol role="list" className="text-gray-700 text-lg">
                <li>
                  1. <b>Strategy Flows</b> help you break down your most
                  audacious goals and projects
                </li>
                <li>
                  2. <b>Writing Flows</b> help you remix ideas that inspire
                  and persuade stakeholders
                </li>
                <li>
                  3. <b>Mentor Flows</b> help you diagnose constraints that
                  hold you back
                </li>
              </ol> */}
              </div>
              <div className="my-10 -ml-6 -mr-4 relative aspect-[16/9] px-5">
                {/* )} */}

                {!showVideo && (
                  <div className="border rounded-md">
                    <Image
                      className="border rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
                      src="/img/landing/screenshots/front.jpg"
                      width={2618}
                      height={1878}
                      quality={70}
                      alt=""
                    />
                    {/* overlay */}
                    {/* <div className="absolute top-[1.3rem] bottom-[1rem] left-[1.3rem] right-[1.3rem] inset-0 bg-black bg-opacity-20 flex items-center justify-center" /> */}
                    <button
                      onClick={() => setShowVideo(true)}
                      className="absolute shadow-xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white bg-sky-800 hover:bg-sky-600 rounded-full p-2"
                    >
                      <FaPlayCircle className="text-8xl" />{' '}
                      {/* Adjust this value as needed */}
                    </button>
                  </div>
                )}
                {showVideo && (
                  <div className="relative pb-[51%] pt-[25px] h-0 mx-0 lg:mx-1 rounded-md">
                    {/* <YouTubeEmbed
                      videoid="4gKdSSDD5V8"
                      height="100%"
                      width="100%"
                      params="controls=0"
                    /> */}
                    <iframe
                      src="https://www.youtube.com/embed/4gKdSSDD5V8?rel=0&showinfo=0&modestbranding=1&autohide=1&playsinline=1"
                      title="Crone Odyssey Teaser"
                      style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        border: '0',
                      }}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                    <Button
                      onClick={() => setShowVideo(true)}
                      className="absolute shadow-xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white bg-sky-800 hover:bg-sky-600 rounded-full p-2"
                    >
                      <FaPlayCircle className="text-8xl" />{' '}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="bg-gray-100 rounded-none lg:rounded-3xl">
            <div className="container max-w-5xl px-3 md:px-5 py-8 mx-auto md:py-12 lg:px-10 xl:py-24 xl:px-0">
              <h2 className="text-4xl font-bold sm:text-center font-serif mb-8">
                Learn from the greatest minds of all time
              </h2>
              <div className="grid gap-3 md:gap-5 grid-cols-1 sm:grid-cols-2 lg:gap-8 xl:grid-cols-4">
                <GreatsSection
                  title="Great Leaders"
                  color="emerald"
                  names={[
                    'Napoleon',
                    'Elizabeth I',
                    'Walt Disney',
                    'Oprah Winfrey',
                    'Orison Marden',
                    'Marcus Aurelius',
                    'Hamilton',
                    'Churchill',
                    'Steve Jobs',
                    'Ben Franklin',
                    'Andrew Carnegie',
                    'Abraham Lincoln',
                    'Sam Altman',
                    'Nelson Mandela',
                    'Caesar',
                  ]}
                />
                <GreatsSection
                  title="Great Writers"
                  color="orange"
                  names={[
                    'Maya Angelou',
                    'T.S. Eliot',
                    'Homer',
                    'Shakespeare',
                    'L.M. Montgomery',
                    'Langston Hughes',
                    'Oscar Wilde',
                    'Dickens',
                    'Octavia Butler',
                    'Rilke',
                    'Emerson',
                    'Sylvia Plath',
                    'Virginia Woolf',
                    'George Orwell',
                    'Jane Austen',
                  ]}
                />
                <GreatsSection
                  title="Great Thinkers"
                  color="sky"
                  names={[
                    'Will Durant',
                    'Aristotle',
                    'Carl Jung',
                    'Maria Popova',
                    'Walter Isaacson',
                    'Plato',
                    'Robert Greene',
                    'Naval Ravikant',
                    'Tim Ferris',
                    'Paul Graham',
                    'Marc Andreessen',
                    'Kierkegaard',
                    'Charlie Munger',
                    'Nietzsche',
                    'William James',
                  ]}
                />
                <GreatsSection
                  title="Great Sages"
                  color="indigo"
                  names={[
                    'Epictetus',
                    'C.S. Lewis',
                    'Mooji',
                    'Khalil Gibran',
                    'D.T. Suzuki',
                    'Papaji',
                    'Ramana Maharshi',
                    'Rabbi Heschel',
                    'Henri Nouwen',
                    'Thomas Merton',
                    'St. Augustine',
                    'Ibn Arabi',
                    'Carl Jung',
                    'Rumi',
                    'Joseph Campbell',
                  ]}
                />
              </div>
            </div>
          </section>

          <SubhookSection
            color="sky"
            title="Feel smarter. Read deeper."
            even={true}
            screenshotName="reading"
          >
            We built Crone as a <i>Duolingo for the intellect</i> so you could
            feel well-rounded, well-grounded, and well-read. Researchers have
            found that ten minutes of active learning is worth over an hour of
            passive consumption.
          </SubhookSection>
          <SubhookSection
            color="emerald"
            title="Gain wisdom and eloquence"
            even={false}
            screenshotName="jigsaw"
          >
            The greatest men and women in history were feverishly active
            learners. They filled the margins of book after book, imitated
            passage after passage, and journaled day after day. Crone helps you
            gain wisdom and eloquence just as they did.
          </SubhookSection>
          <SubhookSection
            color="indigo"
            title="Keep great company"
            even={true}
            screenshotName="answer"
          >
            You&apos;re the average of the five people you spend the most time
            with. Crone puts you in daily conversation with the greatest minds
            in history. Learn from the best. Become the best.
          </SubhookSection>
          <SubhookSection
            color="rose"
            title="Free, playful, life-changing"
            even={false}
            screenshotName="writing"
          >
            Adler said, &quot;In the case of good books, the point is not to see
            how many of them you can get through, but rather how many can get
            through to you.&quot; Crone&apos;s episodes will pierce you to the
            core.
          </SubhookSection>
          <section className="bg-gray-100 rounded-b-3xl lg:rounded-3xl">
            <div className="space-y-8 container max-w-5xl px-5 py-8 mx-auto md:py-12 xl:py-24 lg:px-10 xl:px-0 xl:space-y-12">
              <h2 className="text-4xl font-bold sm:text-center font-serif">
                Join thousands of readers, free
              </h2>
              <div className="grid gap-4 lg:gap-6 md:grid-cols-3">
                <Testimonial
                  description="I'm on an unbroken 200 day streak. Obviously I love it."
                  name="Leila"
                />
                <Testimonial
                  description="My world and brain are bigger. I'd have never had been exposed to [these works] otherwise."
                  name="Kris"
                />
                <Testimonial
                  description="It's changed the way I see reading as a whole."
                  name="India"
                />
              </div>
              <div className="space-y-3 text-center">
                <img
                  src="img/landing/v2/rating.svg"
                  alt="Rating"
                  className="h-6 mx-auto"
                />
                <p className="text-gray-800">Rated 4.9 on the App Store</p>
              </div>
            </div>
          </section>

          {/* <section className="bg-gray-100 rounded-none lg:rounded-3xl">
            <div className="container max-w-5xl px-5 py-8 mx-auto md:py-12 lg:px-10 xl:py-24 xl:px-0">
              <h2 className="mb-8 text-4xl font-bold xl:mb-12 font-serif">
                Pick a course to begin
              </h2>
              <YStack gap="$8" marginTop="$10">
                {paths &&
                  paths?.length > 0 &&
                  paths?.map((path) => (
                    <YStack
                      key={`path_${path.slug}`}
                      gap="$3"
                      padding="$0"
                      $gtSm={{ paddingVertical: '$2' }}
                    >
                      <PathBox path={path} />

                      <YStack display="none" $gtSm={{ display: 'flex' }}>
                        <CourseScroller courses={path.course} />
                      </YStack>
                      <YStack
                        display="flex"
                        gap="$3"
                        $gtSm={{ display: 'none', gap: 0 }}
                      >
                        {path.course?.map((course, k) => {
                          return (
                            <YStack key={`ccBox_${k}_${path.slug}`}>
                              <CourseBox course={course} />
                            </YStack>
                          );
                        })}
                      </YStack>
                    </YStack>
                  ))}
              </YStack>
            </div>
          </section> */}

          <div className="pt-56">
            <div className="mx-auto max-w-xl text-center">
              <RandomBg addClass="left-1/4" index={4} />

              <div className="text-gray-400 mb-3 text-sm">It&apos;s free!</div>
              <div className="flex flex-col items-stretch justify-center gap-3 max-w-64 mx-auto">
                <Link
                  href={{ pathname: '/welcome', query: router.query }}
                  className="cursor-pointer inline-block uppercase font-semibold text-sm text-center p-3 bg-sky-500 text-white shadow-hard-down shadow-[0_4px_0] shadow-sky-700 border-0 rounded-2xl"
                >
                  Crone for web
                </Link>
                <Link
                  href={{ pathname: APP_STORE_LINK, query: router.query }}
                  className="cursor-pointer inline-block uppercase font-semibold text-sm text-center p-3 bg-gray-200 text-gray-500 shadow-hard-down shadow-[0_4px_0] shadow-gray-400 border-0 rounded-2xl"
                >
                  Crone for iPhone
                </Link>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export const getStaticProps: GetStaticProps<PageProps> = async () => {
  const queryClient = new QueryClient();
  await prefetchHomeProps(queryClient);

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default HomeInfoPage;
